.App {
    text-align: center;
}


.App-header {
    background: url("./static/frog.jpg");
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-Photo {
    background: white;
    border-radius: 8px;
    min-height: 400px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-Photo img {
    max-width: 100%;
    height: auto;
}

.App-Photo-Sub {
    background: white;
    border-radius: 8px;
    color: saddlebrown;
}
